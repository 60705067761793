import {
    remapDispatchCenterToOrganization,
    getRelevantApplicationsRefs,
} from './apiHelper';
import { oauth } from '../OAuth';
import { restCall } from './api';
import { DEBUG } from '../config';
import { apiEndpoints } from '../helper/endpointBuilder';

/**
 * get organization
 * @returns {object} response data
 */
export const getOrganizationAPI = async () => {
    return await fetchOrganizationAPI();
};

/**
 * add a new organization
 * @param {object} data
 * @param {object} data.center
 * @param {string} data.secret
 * @returns {object} response data
 */
export const addOrganizationAPI = async ({ center, secret }) => {
    if (DEBUG) console.log('add orga', center);
    const newOrganization = await fetchOrganizationAPI({
        method: 'POST',
        body: JSON.stringify({
            ...remapDispatchCenterToOrganization(center),
            secret: secret,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    await updateOrganizationApplication(newOrganization.id);

    return newOrganization;
};

/**
 * update an existing organization
 * @param {object} center
 * @returns {object} response data
 */
export const updateOrganizationAPI = async center => {
    if (DEBUG) console.log('update orga', center);
    const updatedOrganization = await fetchOrganizationAPI(
        {
            method: 'PATCH',
            body: JSON.stringify({
                ...remapDispatchCenterToOrganization(center),
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        },
        center.id
    );

    await updateOrganizationApplication(updatedOrganization.id);

    return updatedOrganization;
};

/**
 * delete an existing organization for a given id
 * @param {string} id
 * @returns {object} response data
 */
export const deleteOrganizationAPI = async id => {
    const params = {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
        method: 'DELETE',
    };

    await fetch(`${apiEndpoints.REACT_APP_ORGANIZATION_ENDPOINT}/${id}`, params);
};

/**
 * query the organization id
 * @param {object} params
 * @param {string} id
 * @returns {object} response data
 */
export const fetchOrganizationAPI = async (params = {}, id = null) => {
    const mergedParams = {
        withCredentials: true,
        ...params,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            ...params.headers,
        },
    };

    let url = apiEndpoints.REACT_APP_ORGANIZATION_ENDPOINT;
    if (id) {
        url = `${url}/${id}`;
    }

    const result = await restCall(url, mergedParams);

    if (!result.page) {
        if (result._embedded) return result._embedded.organizations;
        return result;
    }

    // get all results if there are more pages
    const limit = result.page.totalElements;
    const organizationsResults = await restCall(
        `${url}?limit=${limit}`,
        mergedParams
    );

    return organizationsResults._embedded.organizations;
};

/**
 * update the applications for a organization with the given id
 * @param {string} id
 * @returns {object} response data
 */
export const updateOrganizationApplication = async id => {
    const refs = getRelevantApplicationsRefs();
    await putApplicationAPI(refs, id);
};

/**
 * query the organization api
 * @param {object} data
 * @param {string} id
 * @returns {object} response data
 */
const putApplicationAPI = async (data, id) => {
    const params = {
        withCredentials: true,
        method: 'PUT',
        body: data,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'text/uri-list',
        },
    };
    await fetch(`${apiEndpoints.REACT_APP_ORGANIZATION_ENDPOINT}/${id}/applications`, params);
};
