import { forceHTTPS } from '../helper';
import { apiEndpoints } from '../helper/endpointBuilder';
import { oauth } from '../OAuth';

const { restCall } = require('./api');

/**
 * get the application configs
 * @returns {object} response data
 */
export const getApplicationConfigsAPI = async () => {
    return await fetchApplicationConfigs(
        {},
        `${apiEndpoints.REACT_APP_APPLICATION_CONFIG_ENDPOINT}?projection=adminData`
    );
};

/**
 * add a new application config
 * @param {object} data
 * @param {object} data.center - current dispatch center
 * @param {array} data.features - enabled features
 * @returns {object} response data
 */
export const addApplicationConfigAPI = async ({ center, features }) => {
    return await fetchApplicationConfigs({
        method: 'POST',
        body: JSON.stringify({
            dispatchCenter: center.centerRef,
            enabledFeatures: [...features],
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * updated an existing application config
 * @param {object} data
 * @param {object} data.center - current dispatch center
 * @param {array} data.features - enabled features
 * @param {object} data.config - current config
 * @returns {object} response data
 */
export const updateApplicationConfigAPI = async ({
    center,
    features,
    config,
}) => {
    return await fetchApplicationConfigs(
        {
            method: 'PUT',
            body: JSON.stringify({
                dispatchCenter: center.centerRef,
                enabledFeatures: [...features],
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        },
        forceHTTPS(config.ref)
    );
};

/**
 * query the application config api
 * @param {object} params
 * @param {string} url
 * @returns {object} response data
 */
const fetchApplicationConfigs = async (
    params = {},
    url = apiEndpoints.REACT_APP_APPLICATION_CONFIG_ENDPOINT
) => {
    const mergedParams = {
        withCredentials: true,
        ...params,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            ...params.headers,
        },
    };

    const result = await restCall(url, mergedParams);

    if (!result.page) {
        if (result._embedded) return result._embedded.applicationConfigs;
        return result;
    }
};
