import React, { useEffect, useState } from 'react';
import { replaceText } from '../../helper';
import { connect } from 'react-redux';

import './CenterMedia.scss';
import { ApplicationConfig, DispatchCenter } from '../../types';
import Dropdown from '../global/Dropdown';
import { DEFAULT_TIMEOUT, SUPPORTED_UNITS_OF_TIME } from '../../config';
import { Tick } from '../icons/Tick';
import {
    getDispatchCenterConfig,
    patchDispatchCenterConfig,
    postDispatchCenterConfig,
} from '../../api/dispatchCenterAPI';
import ToggleSwitch from '../global/ToggleSwitch';
import { CenterImageFormat } from './CenterImageFormat';
import { apiEndpoints } from '../../helper/endpointBuilder';

interface CenterMediaProps {
    texts: { [key: string]: string };
    currentCenter: DispatchCenter;
    applicationConfigs: ApplicationConfig[];
}

const CenterMediaComponent: React.FC<CenterMediaProps> = ({
    texts,
    currentCenter,
    applicationConfigs,
}) => {
    const unitsOfTime = {
        day: 60 * 60 * 24,
        mon: 60 * 60 * 24 * 30.42,
    };

    const [dispatchCenterConfiguration, setDispatchCenterConfiguration] =
        useState();
    const [configurationUrl, setConfigurationUrl] = useState('');
    const [preventAutoDownload, setPreventAutoDownload] = useState(false);
    const [unit, setUnit] = useState('');
    const [unitValue, setUnitValue] = useState('');
    const [unitLimit, setUnitLimit] = useState(null);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const units = {};

    const currentConfig = applicationConfigs.filter(
        config => config.dispatchCenterId === currentCenter.addOnId
    )[0];

    const showDataRetentionSettings =
        currentConfig.enabledFeatures.indexOf('STREAM_RECORDING') !== -1 ||
        currentConfig.enabledFeatures.indexOf('FILE_SHARE') !== -1;
    const streamRecordingIsEnabled =
        currentConfig.enabledFeatures.indexOf('STREAM_RECORDING') !== -1;
    const fileShareIsEnabled =
        currentConfig.enabledFeatures.indexOf('FILE_SHARE') !== -1;

    SUPPORTED_UNITS_OF_TIME.forEach(unit => {
        units[unit] = unit;
    });

    const toggleAutoDownload = async () => {
        if (dispatchCenterConfiguration) {
            await patchDispatchCenterConfig(configurationUrl, {
                preventAutoDownload: !preventAutoDownload,
            });
        }
        setPreventAutoDownload(!preventAutoDownload);
    };

    const handleSelect = async object => {
        if (object.label === 'day') setUnitLimit(30);
        if (object.label === 'mon') setUnitLimit(6);
        setUnit(object.label);
        setUnitValue('');
    };

    const handleUnitInput = e => {
        if (
            /^(?:[0-9]*)$/gi.test(e.target.value) &&
            e.target.value !== '0' &&
            e.target.value !== '00'
        ) {
            if (unit === 'day' && e.target.value < 31)
                setUnitValue(e.target.value);
            if (unit === 'mon' && e.target.value <= 6)
                setUnitValue(e.target.value);
        }
    };

    const buildAndSetConfigURL = config => {
        let pathnameParts = config._links.self.href.split('/');
        let lastParam = pathnameParts[pathnameParts.length - 1];

        let configURL =
            apiEndpoints.REACT_APP_DISPATCHCENTER_CONFIG_ENDPOINT +
            '/' +
            lastParam;

        setConfigurationUrl(configURL);
    };

    const updateSetters = dispatchCenterConfig => {
        setPreventAutoDownload(dispatchCenterConfig.preventAutoDownload);

        if (dispatchCenterConfig.timeToLive !== null) {
            let newTimeInSeconds = 0;
            if (
                dispatchCenterConfig.timeToLive >= 86400 &&
                dispatchCenterConfig.timeToLive < 2628288
            ) {
                setUnit('day');
                newTimeInSeconds =
                    dispatchCenterConfig.timeToLive / 60 / 60 / 24;
                setUnitValue(String(newTimeInSeconds));
                setUnitLimit(30);
            } else if (dispatchCenterConfig.timeToLive >= 2628288) {
                setUnit('mon');
                newTimeInSeconds =
                    dispatchCenterConfig.timeToLive / 60 / 60 / 24 / 30.42;
                setUnitValue(String(Math.ceil(newTimeInSeconds)));
                setUnitLimit(6);
            }
        }
    };

    const saveSettings = async () => {
        if (unit in unitsOfTime) {
            const timeInSeconds = Number(unitValue) * unitsOfTime[unit];
            if (configurationUrl.length !== 0) {
                await patchDispatchCenterConfig(configurationUrl, {
                    timeToLive: timeInSeconds,
                });
            }
        }
        showFormSubmitText();
    };

    const showFormSubmitText = () => {
        setIsFormSubmitted(true);
        setTimeout(() => {
            setIsFormSubmitted(false);
        }, DEFAULT_TIMEOUT);
    };

    useEffect(() => {
        const fetchConfigSettings = async () => {
            const config = await getDispatchCenterConfig(
                currentCenter.addOnId
            ).catch(() => {});

            if (config) {
                buildAndSetConfigURL(config);
                setDispatchCenterConfiguration(config);
                updateSetters(config);
            } else {
                const newConfig = await postDispatchCenterConfig(
                    currentCenter.addOnId,
                    {
                        preventAutoDownload: preventAutoDownload,
                    }
                );
                buildAndSetConfigURL(newConfig);
                setDispatchCenterConfiguration(newConfig);
                updateSetters(newConfig);
            }
        };

        fetchConfigSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="edit-media">
            <h2>{replaceText(texts, 'media.settings')}</h2>
            {currentConfig.enabledFeatures.indexOf('PHOTO') !== -1 && (
                <div className="edit-media__row">
                    <h3>{replaceText(texts, 'media.photo.feature')}</h3>
                    <h3>1. {replaceText(texts, 'image.format')}</h3>
                    <p>
                        {replaceText(texts, 'media.photo.featureDescription')}
                    </p>
                    <CenterImageFormat />
                    <h3>2. {replaceText(texts, 'media.download.header')}</h3>
                    <p>{replaceText(texts, 'media.format.description')}</p>
                    <div className="edit-media__row toggle-wrapper">
                        <ToggleSwitch
                            // Inverse display
                            isActive={!preventAutoDownload}
                            label={replaceText(
                                texts,
                                'photo.autoDownload.toggle'
                            )}
                            changeHandler={toggleAutoDownload}
                        />
                    </div>
                    <div className="edit-media__divider" />
                </div>
            )}
            {showDataRetentionSettings && (
                <>
                    {streamRecordingIsEnabled && !fileShareIsEnabled && (
                        <>
                            <h3>
                                {replaceText(texts, 'stream.recording.heading')}
                            </h3>
                            <p>
                                {replaceText(
                                    texts,
                                    'stream.recording.description'
                                )}
                            </p>
                        </>
                    )}
                    {!streamRecordingIsEnabled && fileShareIsEnabled && (
                        <>
                            <h3>{replaceText(texts, 'file.share.header')}</h3>
                            <p>
                                {replaceText(texts, 'file.share.description')}
                            </p>
                        </>
                    )}
                    {streamRecordingIsEnabled && fileShareIsEnabled && (
                        <>
                            <h3>
                                {replaceText(texts, 'media.retention.header')}
                            </h3>
                            <p>
                                {replaceText(
                                    texts,
                                    'media.retention.description'
                                )}
                            </p>
                        </>
                    )}
                    <div className="media-retention__container">
                        <input
                            type="tel"
                            value={unitValue}
                            onChange={handleUnitInput}
                            maxLength={2}
                            placeholder={replaceText(texts, 'unit.select', unitLimit)}
                            disabled={unit.length === 0}></input>
                        <Dropdown
                            hasFlags={true}
                            minHeight={20}
                            clickHandler={handleSelect}
                            label={
                                units[unit] &&
                                replaceText(texts, `time.${units[unit]}`)
                            }>
                            <TimeOptions
                                units={units}
                                currentUnits={unit}
                                texts={texts}
                            />
                        </Dropdown>
                        {unitLimit !== null && (
                            <span className="hint">
                                {replaceText(texts, 'unit.limit', unitLimit)}
                            </span>
                        )}
                    </div>
                    <div className="edit-media__divider" />
                    <button
                        className="btn btn--primary"
                        onClick={saveSettings}
                        disabled={
                            unit.length === 0 ||
                            unitValue.length === 0 ||
                            isFormSubmitted
                        }>
                        {replaceText(texts, 'action.save')}
                    </button>
                    <div
                        className={`success-message ${
                            isFormSubmitted ? 'success-message--isShown' : ''
                        }`}>
                        <span>{replaceText(texts, 'settings.saved')}</span>
                    </div>
                </>
            )}
        </div>
    );
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        applicationConfigs: state.applicationConfigs,
    };
};

// Connect Props and Dispatch to Component
export const CenterMedia = connect(mapStateToProps)(CenterMediaComponent);

function TimeOptions(props: any) {
    const displayUnitInSelectedLanguage = (unit: String) => {
        return replaceText(props.texts, `time.${unit}`);
    };

    return (
        <div>
            {Object.keys(props.units).map(unit => (
                <div
                    className="time-unit__option"
                    key={unit}
                    onClick={() => {
                        props.clickElement(unit);
                    }}>
                    <span>
                        {unit !== typeof 'undefined' &&
                            displayUnitInSelectedLanguage(props.units[unit])}
                    </span>
                    <span>{unit === props.currentUnits && <Tick />}</span>
                </div>
            ))}
        </div>
    );
}
