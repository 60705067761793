import { remapUser, getScopes, getOrganization } from './apiHelper';
import { oauth } from '../OAuth';
import { restCall } from './api';
import { mergeTwoArraysWithObjects } from '../helper/mergeTwoArraysWithObjects';
import { apiEndpoints } from '../helper/endpointBuilder';
import { systemConfig } from '../helper/systemConfigBuilder';

/**
 * get users
 * @returns {object} response data
 */
export const getUsersAPI = async () => {
    const userList = await exportUsersAPI();
    const users = await fetchUserAPI({}, false, true);

    const mergedUserList = mergeTwoArraysWithObjects(userList, users, 'email');

    return mergedUserList;
};

/**
 * get user
 * @returns {object} response data
 */
export const getUserAPI = async (id, useProjection = true) => {
    return await fetchUserAPI({}, id, useProjection);
};

/**
 * add a new user
 * @param {object} user
 * @returns {object} response data
 */
export const addUserAPI = async (user, checkIfEmailExists = false) => {
    const remappedUser = remapUser(user);
    remappedUser.organization = getOrganization(user.organization.name);
    const newUser = await fetchUserAPI(
        {
            method: 'POST',
            body: JSON.stringify(remappedUser),
            headers: {
                'Content-Type': 'application/json',
            },
        },
        false,
        false,
        checkIfEmailExists
    );

    if (newUser && newUser.status === 409) {
        return newUser;
    }

    await updateUserScope({
        id: newUser.id,
        role: user.role,
    });

    return await getUserAPI(newUser.id);
};

/**
 * update an existing user
 * @param {object} user
 * @returns {object} response data
 */
export const updateUserAPI = async user => {
    const remappedUser = remapUser(user);

    await fetchUserAPI(
        {
            method: 'PATCH',
            body: JSON.stringify(remappedUser),
            headers: {
                'Content-Type': 'application/json',
            },
        },
        user.id
    );

    await updateUserOrganization({
        center: user.organization.name,
        id: user.id,
    });

    await updateUserScope({
        id: user.id,
        role: user.role,
    });

    return await getUserAPI(user.id);
};

/**
 * update the organization for a specific user
 * @param {object} data
 * @param {object} data.center
 * @param {string} data.id
 * @returns {object} response data
 */
const updateUserOrganization = async ({ center, id }) => {
    await putOrganizationOrScopeAPI(
        getOrganization(center),
        id,
        'organization'
    );
};

/**
 * update the user scope
 * @param {object} data
 * @param {string} data.role
 * @param {string} data.id
 * @returns {object} response data
 */
const updateUserScope = async ({ role, id }) => {
    await putOrganizationOrScopeAPI(getScopes(role), id, 'scopeList');
};

/**
 * delete a user for the givin id
 * @param {string} id
 * @returns {object} response data
 */
export const deleteUserAPI = async id => {
    const params = {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
        method: 'DELETE',
    };

    await restCall(`${apiEndpoints.REACT_APP_USER_ENDPOINT}/${id}`, params, false);
};

/**
 * delete a user for the given email
 * @param {string} email
 * @returns {object} response data
 */

export const deleteUserByEmail = async (email) => {
    const params = {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
        method: 'DELETE',
    };

    await restCall(`${apiEndpoints.REACT_APP_USER_DELETE}${email}`, params, false);
};

/**
 * query the user api to update organization or the scope
 * @param {object} data
 * @param {string} id
 * @param {string} add
 * @returns {object} response data
 */
const putOrganizationOrScopeAPI = async (data, id, add) => {
    const params = {
        withCredentials: true,
        method: 'PUT',
        body: data,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'text/uri-list',
        },
    };
    await restCall(`${apiEndpoints.REACT_APP_USER_ENDPOINT}/${id}/${add}`, params, false);
};

/**
 * query the user api
 * @param {object} params
 * @param {string} id
 * @param {boolean} useProjection
 * @returns {object} response data
 */
const fetchUserAPI = async (
    params = {},
    id = false,
    useProjection = false,
    checkIfEmailExists = false
) => {
    const mergedParams = {
        withCredentials: true,
        ...params,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            ...params.headers,
        },
    };

    let url = apiEndpoints.REACT_APP_USER_ENDPOINT;
    if (id) {
        url = `${url}/${id}`;
    }
    if (useProjection) {
        url = `${url}?projection=adminData`;
    }

    const result = await restCall(url, mergedParams);

    if (checkIfEmailExists) {
        return result;
    }

    if (!result.page) {
        if (result._embedded) return result._embedded.users;
        return result;
    }

    // get all results if there are more pages
    const limit = result.page.totalElements;
    const usersResults = await restCall(`${url}&limit=${limit}`, mergedParams);

    return usersResults._embedded.users;
};

export const exportUsersAPI = async (returnCSV = false) => {
    const params = {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            Accept: returnCSV ? 'text/csv' : 'application/json',
        },
    };

    const result = await restCall(`${apiEndpoints.REACT_APP_USER_EXPORT_ENDPOINT}`, params, false);

    if (returnCSV) {
        return await result.text();
    } else {
        return await result.json();
    }
};

export const sendPasswordChangeInvite = async user => {
    const data = {
        userName: user,
        clientId: systemConfig.REACT_APP_ADMIN_AUTH_CLIENT_ID,
    };

    const params = {
        withCredentials: true,
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
    };

    await restCall(`${apiEndpoints.REACT_APP_SEND_PASSWORD_CHANGE_INVITE}`, params, false);
};

export const deletePasswordRequest = async id => {
    let params = {
        withCredentials: true,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
    };

    const result = await restCall(`${apiEndpoints.REACT_APP_PASSWORD_REQUEST_GET}/${id}`, params);

    if (result) {
        let link = result._links.pWRequest.href;
        // if link exists, delete password request
        if (link) {
            params = {
                ...params,
                method: 'DELETE',
            };
            await restCall(link, params, false);
        }
    }
    return result;
};
