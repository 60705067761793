export const endpointKeys = [
    'REACT_APP_AUTH_LOGIN_CALLBACK',
    'REACT_APP_AUTH_LOGIN_URL',
    'REACT_APP_USER_ENDPOINT',
    'REACT_APP_ORGANIZATION_ENDPOINT',
    'REACT_APP_SCOPES_ENDPOINT',
    'REACT_APP_APPLICATIONS_ENDPOINT',
    'REACT_APP_PASSWORD_ENDPOINT',
    'REACT_APP_API_TOKEN_ENDPOINT',
    'REACT_APP_SYSTEMHEALTH_ENDPOINT',
    'REACT_APP_WELCOMEMESSAGES_ENDPOINT',
    'REACT_APP_DISPATCHCENTERS_ENDPOINT',
    'REACT_APP_APPLICATION_CONFIG_ENDPOINT',
    'REACT_APP_LOGOUT_TOKEN_ENDPOINT',
    'REACT_APP_LOGO_UPLOAD_ENDPOINT',
    'REACT_APP_LOGO_ENDPOINT',
    'REACT_APP_CONTACTS_ENDPOINT',
    'REACT_APP_LANGUAGE_GET',
    'REACT_APP_LANGUAGE_SET',
    'REACT_APP_MIMETYPE_ENDPOINT',
    'REACT_APP_POST_USER_CONFIGURATION',
    'REACT_APP_POST_USER_CONFIGURATION_DELETE',
    'REACT_APP_CONFERENCE_LOGO_POST',
    'REACT_APP_CONFERENCE_LOGO_ENDPOINT',
    'REACT_APP_CONFERENCE_IMAGE_MIMETYPE',
    'REACT_APP_CONFERENCE_CONFIG_ENDPOINT',
    'REACT_APP_CONFERENCE_CONFIG_DISPATCHER_CENTER',
    'REACT_APP_CONFERENCE_EMAIL_CONFIG_ENDPOINT',
    'REACT_APP_CONFERENCE_EMAIL_DISPATCH_CENTER',
    'REACT_APP_SESSION_REPORT_CONFIG_ENDPOINT',
    'REACT_APP_SESSION_REPORT_CONFIG_DISPATCH_CENTER',
    'REACT_APP_ORG_STRUCTURE_CONFIG_ENDPOINT',
    'REACT_APP_ORG_STRUCTURE_DISPATCH_CENTER',
    'REACT_APP_DISPATCHCENTER_CONFIG_ENDPOINT',
    'REACT_APP_DISPATCHCENTER_CONFIG_DISPATCH_CENTER',
    'REACT_APP_DISCLAIMER_CONFIG_ENDPOINT',
    'REACT_APP_DISCLAIMER_CONFIG_DISPATCH_CENTER',
    'REACT_APP_DISCLAIMER_RESET',
    'REACT_APP_USER_EXPORT_ENDPOINT',
    'REACT_APP_PASSWORD_POLICY_CONFIG_ENDPOINT',
    'REACT_APP_PASSWORD_POLICY_CONFIG_DISPATCH_CENTER',
    'REACT_APP_SEND_PASSWORD_CHANGE_INVITE',
    'REACT_APP_AZURE_STATUS_GET',
    'REACT_APP_PASSWORD_REQUEST_GET',
    'REACT_APP_BACKGROUND_IMAGE_UPLOAD_ENDPOINT',
    'REACT_APP_BACKGROUND_IMAGE_ENDPOINT',
    'REACT_APP_USER_DELETE',
    'REACT_APP_DISPATCHERCENTER_DELETE',
    'REACT_APP_IPRESTRICTIONS_ENDPOINT',
    'REACT_APP_IPRESTRICTIONS_GET_BY_ORG',
];
