/**
 * get texts in a specific language
 * @param {string} lang language to get
 * @return {object} texts in specific language
 */

import { apiEndpoints } from '../helper/endpointBuilder';
import { systemConfig } from '../helper/systemConfigBuilder';
import { oauth } from '../OAuth';
import { restCall } from './api';

export const getLanguageFile = async lang => {
    try {
        let defaultFile = `/texts/${lang}/default.json`;

        const defaultTexts = await restCall(defaultFile);

        if (process.env.REACT_APP_LANGUAGE_PACK) {
            let overwriteFile = `/texts/${lang}/${process.env.REACT_APP_LANGUAGE_PACK}.json`;

            const overwriteTexts = await restCall(overwriteFile);

            return {
                ...defaultTexts,
                ...overwriteTexts,
            };
        }

        return { ...defaultTexts };
    } catch (e) {
        console.log(e);
    }
};

/**
 * get the saved language for the current user
 * returns the default language if language is not set
 * @returns {string} language handle
 */

export const getLanguageForUser = async () => {
    try {
        const res = await restCall(
            apiEndpoints.REACT_APP_LANGUAGE_GET,
            {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${oauth.bearerToken}`,
                },
            },
            false
        )
        return await res.text();
    }
    catch(e) {
        return systemConfig.REACT_APP_LANGUAGE;
    }
};

/**
 * sets the language for the current dispatch user
 * @param {string} languageHandle language handle
 */

export const setLanguageForUser = async languageHandle => {
    return await restCall(
        apiEndpoints.REACT_APP_LANGUAGE_SET,
        {
            method: 'POST',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${oauth.bearerToken}`,
            },
            body: JSON.stringify({
                language: languageHandle,
            }),
        },
        false
    ).catch(e => {
        console.log(e);
    });
};
