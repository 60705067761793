import { forceHTTPS } from '../helper';
import { apiEndpoints } from '../helper/endpointBuilder';
import { oauth } from '../OAuth';
import { restCall } from './api';

export const postUserConfiguration = async ({ userID, language }) => {
    const parameters = {
        method: 'POST',
        withCredentials: true,
        body: JSON.stringify({
            userId: userID,
            language: language,
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    };

    const result = await restCall(
        apiEndpoints.REACT_APP_POST_USER_CONFIGURATION,
        parameters
    );
    return result;
};

export const deleteUserConfiguration = async userID => {
    const parameters = {
        method: 'DELETE',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    };

    const result = await restCall(
        `${apiEndpoints.REACT_APP_POST_USER_CONFIGURATION_DELETE}?userId=${userID}`,
        parameters,
        false
    );
    return result;
};

export const getUserConfiguration = async user => {
    const parameters = {
        method: 'GET',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    };

    const userConfigurationURL =
        apiEndpoints.REACT_APP_POST_USER_CONFIGURATION +
        '/search/findUserConfigurationByUserId?userId=' +
        user;

    try {
        const result = await restCall(userConfigurationURL, parameters);

        if (result) {
            return forceHTTPS(result._links.orgStructureItems.href);
        } else {
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const putUserOrgStructure = async (urlRef, items = []) => {
    let orgStructureItemUrl =
        apiEndpoints.REACT_APP_ORG_STRUCTURE_CONFIG_ENDPOINT;
    let addressList = '';

    items.map(item => {
        addressList = addressList + orgStructureItemUrl + '/' + item + '\r\n';
        return null;
    });

    addressList = addressList.replace(/[\r\n]+$/, '');

    const parameters = {
        method: 'PUT',
        withCredentials: true,
        body: addressList,
        headers: {
            'Content-Type': 'text/uri-list',
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    };

    const result = await restCall(urlRef, parameters, false);
    return result;
};

export const getCurrentUserOrgStructure = async userOrgStructureURL => {
    const parameters = {
        method: 'GET',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    };

    const result = await restCall(userOrgStructureURL, parameters);
    return result._embedded.orgStructureItems;
};
