import { apiEndpoints } from '../helper/endpointBuilder';
import { oauth } from '../OAuth';
import { DisclaimerConfiguration } from '../types';

import { restCall } from './api';

interface DisclaimerConfigurationResponse extends DisclaimerConfiguration {
    id: number;
    _links: any;
}

export const getDisclaimerConfigurationForDispatchCenter = async addOnId => {
    const dispatchCenterConfigURL = `${apiEndpoints.REACT_APP_DISCLAIMER_CONFIG_DISPATCH_CENTER}/${addOnId}`;

    const res: DisclaimerConfigurationResponse = await restCall(dispatchCenterConfigURL, {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });

    return res;
}

interface DisclaimerConfigurationPostData extends DisclaimerConfiguration {
    addOnId: number;
}

export const updateDisclaimerConfiguration = async ({
    addOnId,
    disclaimerTextDispatcher = "",
    disclaimerTextBystander = "",
    confirmationText = "",
    enabledForDispatcher,
    enabledForBystander,
    confirmationEnabledForDispatcher,
}: DisclaimerConfigurationPostData) => {
    const centerRef = `${apiEndpoints.REACT_APP_DISPATCHCENTERS_ENDPOINT}/${addOnId}`;
    let method = 'PATCH';
    let url = apiEndpoints.REACT_APP_DISCLAIMER_CONFIG_ENDPOINT;

    // check if there is already a disclaimer configuration for this dispatch center
    // if not (e.g. 404, 403) then create a new one (POST)
    const response = await getDisclaimerConfigurationForDispatchCenter(addOnId);
    // if there is no response, then create a new disclaimer configuration
    if (!response) method = 'POST';
    // add the disclaimer configuration id to the url if it exists
    if (response) url += `/${response.id}`;

    const data = {
        disclaimerTextDispatcher,
        disclaimerTextBystander,
        confirmationText,
        enabledForBystander,
        enabledForDispatcher,
        confirmationEnabledForDispatcher,
        "dispatchCenter": centerRef
    }

    const mergedParams = {
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method,
        withCredentials: true,
    };

    return await restCall(url, mergedParams) as DisclaimerConfiguration;
};

export const resetGivenConfirmationsForDispatchCenter = async (id: number) => {
    await restCall(`${apiEndpoints.REACT_APP_DISCLAIMER_RESET}?dispatchCenterId=${id}`, {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
            dispatchCenterId: id
        })
    }, false);
}