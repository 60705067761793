import { apiEndpoints } from '../helper/endpointBuilder';
import { oauth } from '../OAuth';
import { PasswordPolicyConfiguration } from '../types';

import { restCall } from './api';

interface PasswordConfigurationResponse extends PasswordPolicyConfiguration {
    id: number;
    _links: any;
}

export const getPasswordPolicyForDispatchCenter = async addOnId => {
    const dispatchCenterConfigURL = `${apiEndpoints.REACT_APP_PASSWORD_POLICY_CONFIG_DISPATCH_CENTER}/${addOnId}`;

    const res: PasswordConfigurationResponse = await restCall(
        dispatchCenterConfigURL,
        {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${oauth.bearerToken}`,
                'Content-Type': 'application/json',
            },
            method: 'GET',
        }
    );

    return res;
};

interface PasswordPolicyConfigurationPostData
    extends PasswordPolicyConfiguration {
    addOnId: number;
}

export const updatePasswordPolicyConfiguration = async ({
    addOnId,
    enabledPolicies = [],
    minimumPasswordLength = 0,
    passwordExpirationLength = 0
}: PasswordPolicyConfigurationPostData) => {
    const centerRef = `${apiEndpoints.REACT_APP_DISPATCHCENTERS_ENDPOINT}/${addOnId}`;
    let method = 'PATCH';
    let url = apiEndpoints.REACT_APP_PASSWORD_POLICY_CONFIG_ENDPOINT;

    // check if there is already a password policy configuration for this dispatch center
    // if not (e.g. 404, 403) then create a new one (POST)
    const response = await getPasswordPolicyForDispatchCenter(addOnId);
    // if there is no response, then create a new disclaimer configuration
    if (!response) method = 'POST';
    // add the password policy configuration id to the url if it exists
    if (response) url += `/${response.id}`;

    const data = {
        enabledPolicies,
        minimumPasswordLength,
        passwordExpirationLength,
        organization: centerRef,
    };

    const mergedParams = {
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method,
        withCredentials: true,
    };

    return (await restCall(url, mergedParams)) as PasswordPolicyConfiguration;
};