import { oauth } from '../OAuth';
import { remapDispatchCenterToAddOn, getAddOnId } from './apiHelper';
import store from '../redux/store';
import { restCall } from './api';
import { apiEndpoints } from '../helper/endpointBuilder';

/**
 * get dispatch centers
 * @returns {object} response data
 */
export const getDispatchCenterAPI = async () => {
    return await fetchDispatchCenterAPI();
};

/**
 * add a new dispatch center
 * @param {object} center
 * @returns {object} response data
 */
export const addDispatchCenterAPI = async center => {
    return await fetchDispatchCenterAPI({
        method: 'POST',
        body: JSON.stringify({
            ...remapDispatchCenterToAddOn(center),
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * update an existing dispatch center
 * @param {object} center
 * @returns {object} response data
 */
export const updateDispatchCenterAPI = async center => {
    return await fetchDispatchCenterAPI(
        {
            method: center.addOnId ? 'PUT' : 'POST',
            body: JSON.stringify({
                ...remapDispatchCenterToAddOn(center),
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        },
        center.addOnId
    );
};

/**
 * delete an existing dispatch center for a given id
 * @param {string} orgId
 * @returns {object} response data
 */
export const deleteDispatchCenterAPI = async orgId => {
    const params = {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
        method: 'DELETE',
    };

    const addOnId = getAddOnId(store.getState().dispatchCenters.centers, orgId);

    if (!addOnId) return;
    await fetch(`${apiEndpoints.REACT_APP_DISPATCHERCENTER_DELETE}${addOnId}`, params);
};

/**
 * query the dispatch center api
 * @param {object} params
 * @param {string} id
 * @returns {object} response data
 */
export const fetchDispatchCenterAPI = async (params = {}, id = null) => {
    const mergedParams = {
        withCredentials: true,
        ...params,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            ...params.headers,
        },
    };

    let url = apiEndpoints.REACT_APP_DISPATCHCENTERS_ENDPOINT;
    if (id) {
        url = `${url}/${id}`;
    }

    const result = await restCall(url, mergedParams);

    if (!result.page) {
        if (result._embedded) return result._embedded.dispatchCenters;
        return result;
    }

    // get all results if there are more pages
    const limit = result.page.totalElements;
    const dispatchCentersResults = await restCall(
        `${url}?limit=${limit}`,
        mergedParams
    );

    return dispatchCentersResults._embedded.dispatchCenters;
};

/**
 * add time-to-live to dispatch center config
 * @returns {object} response data
 */
export const postDispatchCenterConfig = async (addOnId, updateProp) => {
    const dispatchCenterId = `${apiEndpoints.REACT_APP_DISPATCHCENTER_CONFIG_ENDPOINT}/${addOnId}`;
    let updateKey;
    let updateValue;

    for (const [key, value] of Object.entries(updateProp)) {
        updateKey = key;
        updateValue = value;
    }

    let url = apiEndpoints.REACT_APP_DISPATCHCENTER_CONFIG_ENDPOINT;

    let data = {
        dispatchCenter: dispatchCenterId,
        [updateKey]: updateValue,
    };

    const mergedParams = {
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        withCredentials: true,
    };

    return restCall(url, mergedParams);
};

/**
 * get all details related to dispatch center config
 * @returns {object} response data
 */
export const getDispatchCenterConfig = async centerId => {
    const dispatchCenterConfigURL = `${apiEndpoints.REACT_APP_DISPATCHCENTER_CONFIG_DISPATCH_CENTER}/${centerId}`;

    const res = await restCall(dispatchCenterConfigURL, {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'GET',
    }).catch(e => {
        console.log(e);
    });

    if (res.status !== 404 && res.status !== 403) return { ...res };
    else return null;
};

/**
 * update dispatch center config
 * @returns {object} response data
 */
export const patchDispatchCenterConfig = async (
    dispatchCenterConfigIdUrl,
    updateProp
) => {
    let url = dispatchCenterConfigIdUrl;
    let updateKey;
    let updateValue;

    for (const [key, value] of Object.entries(updateProp)) {
        updateKey = key;
        updateValue = value;
    }

    let data = {
        [updateKey]: updateValue,
    };

    const mergedParams = {
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'PATCH',
        withCredentials: true,
    };

    return restCall(url, mergedParams);
};
