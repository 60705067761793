import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';

import { getTheme, getTitle } from './helper';
import { getLanguageFile, getLanguageForUser } from './api/translations';

import Login from './components/Login';
import UserListView from './components/userlist/UserListView';
import NewUser from './components/userlist/NewUser';
import EditUser from './components/userlist/EditUser';
import Messages from './components/messages/Messages';
import PrivateRoute from './components/PrivateRoute';
import OAuth from './components/OAuth';
import ErrorDisplay from './components/global/ErrorDisplay';
import CentersListView from './components/centers/CentersListView';
import EditCenter from './components/centers/EditCenter';
import NewCenter from './components/centers/NewCenter';
import { setLanguageDispatch, setTextsDispatch } from './redux/actions';
import { loadTheme } from './helper/themeLoader';

/**
 * Takes care of the routing of the application. Some routes are guarded via private route.
 *
 * @component PrivateRoute - guards certain routes
 * @component Login - redirects to the login page
 * @component OAuth - application entry point, loads data and handle authenification
 * @component ErrorDisplay - shows the errors
 * @component UserListView - shows the user list
 * @component NewUser - new user formular
 * @component NewCenter - new center formular
 * @component Messages - application messages for the frontend formular
 * @component CentersListView - shows the dispatch centers
 * @component EditUser - edit user formular
 * @component EditCenter - edit center formular
 */

const App = ({ isLoggedIn, language }) => {
    useEffect(() => {
        if (isLoggedIn) {
            getLanguage();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (isLoggedIn) {
            getTexts(language);
        }
    }, [isLoggedIn, language]);

    document.title = getTitle();
    document.querySelector("link[rel='shortcut icon']").href =
    'config/themes/' + getTheme() + '/favicon.ico';
    loadTheme();

    const getLanguage = async () => {
        const languageForUser = await getLanguageForUser();

        if (languageForUser) {
            setLanguageDispatch(languageForUser);
        }
    };

    const getTexts = async language => {
        const texts = await getLanguageFile(language);

        if (texts) {
            setTextsDispatch(texts);
        }
    };

    return (
        <Router>
            <Switch>
                <Route exact path="/" render={() => <Redirect to="/login" />} />
                <Route path="/login" component={Login} />
                <Route path="/oauth" component={OAuth} />
                <Route path="/error/:type/:message" component={ErrorDisplay} />
                <PrivateRoute path="/user" component={UserListView} />
                <PrivateRoute path="/new/user" component={NewUser} />
                <PrivateRoute path="/new/center" component={NewCenter} />
                <PrivateRoute
                    path="/messages"
                    role="superadmin" // eslint-disable-line
                    component={Messages}
                />
                <PrivateRoute
                    path="/center"
                    role="admin" // eslint-disable-line
                    component={CentersListView}
                />
                <PrivateRoute path="/edit/user/:id" component={EditUser} />
                <PrivateRoute path="/edit/center/:id" component={EditCenter} />
                {/* <Route component={page404} /> */}
            </Switch>
        </Router>
    );
};

// PropTypes for this Component
App.propTypes = {
    isLoggedIn: PropTypes.bool,
    language: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.currentUser.isLoggedIn,
        language: state.texts.language,
    };
};

// Connect Props to Component
export default connect(mapStateToProps)(App);
