import { systemConfigKeys } from './systemConfigKeys';

// Uses dynamic value stored in globalSystemConfig. Falls back to .env value if globalSystemConfig value is empty
const setConfigEntry = key => {
    const configValue =
        window.globalSystemConfig && window.globalSystemConfig[key];
    if (configValue) {
        switch (typeof configValue) {
            case 'string':
                if (configValue.length > 0) {
                    return configValue;
                }
                break;
            case 'boolean':
                return configValue;
            default:
                break;
        }
    }

    return process.env[key];
};

const systemConfig = Object.fromEntries(
    systemConfigKeys.map(key => [key, setConfigEntry(key)])
);

export { systemConfig };
