import { apiEndpoints } from '../helper/endpointBuilder';
import { oauth } from '../OAuth';
import { restCall } from './api';

// DISPATCHER ONLY

/**
 * add all details related to conferencing invitation and goodbye page
 * @returns {object} response data
 */
export const postConferenceConfig = async ({
    addOnId,
    senderName,
    subject,
    emailText,
    conferenceEnd,
}) => {
    const dispatchCenterId = `${apiEndpoints.REACT_APP_DISPATCHCENTERS_ENDPOINT}/${addOnId}`;
    let url = apiEndpoints.REACT_APP_CONFERENCE_CONFIG_ENDPOINT;
    let end = '';

    // if conferenceEnd markdown contains visible characters, send to endpoint. Else send empty string

    if (/[A-Z]/gi.test(conferenceEnd)) {
        end = conferenceEnd;
    }

    const mergedParams = {
        body: JSON.stringify({
            dispatchCenter: dispatchCenterId,
            messageSender: senderName,
            messageSubject: subject,
            messageText: emailText,
            goodbyeText: end,
        }),
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        withCredentials: true,
    };

    return restCall(url, mergedParams);
};
/**
 * update all details related to conferencing invitation and goodbye page
 * @returns {object} response data
 */
export const patchConferenceConfig = async ({
    inviteConfigId,
    addOnId,
    senderName,
    subject,
    emailText,
    conferenceEnd,
}) => {
    const dispatchCenterId = `${apiEndpoints.REACT_APP_DISPATCHCENTERS_ENDPOINT}/${addOnId}`;

    let url = `${apiEndpoints.REACT_APP_CONFERENCE_CONFIG_ENDPOINT}/${inviteConfigId}`;

    let end = '';

    // if conferenceEnd markdown contains visible characters, send to endpoint. Else send empty string
    if (/[A-Z]/gi.test(conferenceEnd)) {
        end = conferenceEnd;
    }

    const mergedParams = {
        body: JSON.stringify({
            dispatcherCenter: dispatchCenterId,
            messageSender: senderName,
            messageSubject: subject,
            messageText: emailText,
            goodbyeText: end,
        }),
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'PATCH',
        withCredentials: true,
    };

    return restCall(url, mergedParams);
};

/**
 * get all details related to conferencing invitation and goodbye page
 * @returns {object} response data
 */
export const getInviteConfig = async centerId => {
    const dispatchCenterConfigURL = `${apiEndpoints.REACT_APP_CONFERENCE_CONFIG_DISPATCHER_CENTER}/${centerId}`;

    const res = await restCall(
        dispatchCenterConfigURL,
        {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${oauth.bearerToken}`,
                'Content-Type': 'application/json',
            },
            method: 'GET',
        }
        // false
    ).catch(e => {
        console.log('getInviteConfig', e);
    });

    return { ...res };
};

/**
 * delete specified invite config via center addOnId
 * @returns {object} response data
 */
export const deleteInviteConfig = async centerId => {
    const inviteConfig = await getInviteConfig(centerId);

    if (inviteConfig && inviteConfig.id) {
        const params = {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${oauth.bearerToken}`,
            },
            method: 'DELETE',
        };

        await fetch(`${apiEndpoints.REACT_APP_CONFERENCE_CONFIG_ENDPOINT}/${inviteConfig.id}`, params);
    }
};

export const getAllInviteConfigs = async () => {
    const res = await restCall(apiEndpoints.REACT_APP_CONFERENCE_CONFIG_ENDPOINT, {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'GET',
    }).catch(e => {
        console.log('getInviteConfig', e);
    });

    if (res) {
        if (res._embedded) {
            const configs = res._embedded.inviteConfigurations;
            return configs;
        }
    }

    return {};
};

export const getEmailConfig = async centerId => {
    const getEmailConfigURL = `${apiEndpoints.REACT_APP_CONFERENCE_EMAIL_DISPATCH_CENTER}/${centerId}`;
    const res = await restCall(
        getEmailConfigURL,
        {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${oauth.bearerToken}`,
                'Content-Type': 'application/json',
            },
            method: 'GET',
        }
        // false
    ).catch(e => {
        console.log('getEmailConfig', e);
    });

    return { ...res };
};

/**
 * add sender name to email config
 * @returns {object} response data
 */
export const postEmailConfig = async (addOnId, senderName) => {
    const dispatchCenterId = `${apiEndpoints.REACT_APP_DISPATCHCENTERS_ENDPOINT}/${addOnId}`;
    const url = apiEndpoints.REACT_APP_CONFERENCE_EMAIL_CONFIG_ENDPOINT;

    const mergedParams = {
        body: JSON.stringify({
            dispatchCenter: dispatchCenterId,
            senderName: senderName,
        }),
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        withCredentials: true,
    };

    return restCall(url, mergedParams);
};

/**
 * update email config sender name
 * @returns {object} response data
 */
export const patchEmailConfig = async (emailConfigId, addOnId, senderName) => {
    const dispatchCenterId = `${apiEndpoints.REACT_APP_DISPATCHCENTERS_ENDPOINT}/${addOnId}`;

    let url = `${apiEndpoints.REACT_APP_CONFERENCE_EMAIL_CONFIG_ENDPOINT}/${emailConfigId}`;

    const mergedParams = {
        body: JSON.stringify({
            dispatcherCenter: dispatchCenterId,
            senderName: senderName,
        }),
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'PATCH',
        withCredentials: true,
    };

    return restCall(url, mergedParams);
};
